body {
  display: flex;
  flex-grow: 1;
}
.footer {
  display: flex;
  flex-shrink: 0;
  background-color: black;
  color: white;
  margin: -0.5em;
  min-width: 100vw;
}

.footerChild {
  margin-left: 1em;
  margin-right: 1em;
}

.question {
  display: flex;
  margin: 1em;
  padding: 1em 1em;
  height: 1em;
  text-align: center;
}

.imgBtnGrp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 409px;
  padding: 5em 0.1em;
  margin-top: 0 auto;
  justify-content: center;
}

.imgBtn {
  min-height: 150px;
  max-height: 150px;
  min-width: 150px;
  max-width: 150px;
  margin: 1px;
  border-radius: 0.5em;
  padding: 0.1em 0.1em 0.1em 0.1em;
}

.header {
  display: flex;
  min-width: 100vw;
  background-color: black;
  color: white;
  margin: -0.5em;
}

.headerChild {
  margin: 1em;
  padding: 0.2em;
}

.results {
  display: flex;
  flex-direction: column;
  margin: 1em auto;
  padding: 1em;
}

.fbShare {
  margin-top: 2em;
}

.waShare {
  margin-top: 1em;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  background-color: white;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}

a {
  font-size: 1rem;
  color: white;
}

.personalityText {
  box-sizing: border-box;
  border: 1px solid black;
  padding: 2em;
  margin: 0.5em;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.cycLogo {
  max-height: 50px;
}
